import React, { HTMLProps } from 'react';

import { Colors } from '../utils/style-utils';

interface Props extends HTMLProps<HTMLDivElement> {
  fill: string;
  size: number;
}

function CheckIcon(props: Props): JSX.Element {
  return (
    <div {...props}>
      <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.99997 8.58603L1.70697 5.29303L0.292969 6.70703L4.99997 11.414L14.707 1.70703L13.293 0.29303L4.99997 8.58603Z"
          fill={props.fill}
        />
      </svg>
    </div>
  );
}

CheckIcon.defaultProps = {
  fill: Colors.Green500,
  size: 16,
};

export { CheckIcon };
