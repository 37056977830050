import React, {
  CSSProperties,
  FC,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  forwardRef,
  isValidElement,
  useState,
} from 'react';
import { useUID } from 'react-uid';
import styled from 'styled-components';

import { isEmptyOrNil } from '../utils/function-utils';
import { Colors } from '../utils/style-utils';
import { Label } from './Label';

export interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
  ref?: ((instance: HTMLInputElement | null) => void) | React.MutableRefObject<HTMLInputElement | null> | null;
  label?: string;
  helpText?: ReactElement | string;
  fullWidth?: boolean;
  inputStyle?: CSSProperties;
  error?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextInput = styled.input<PasswordInputProps>`
  padding: 10px;
  background: ${({ error }) => (error ? 'rgba(235, 87, 87, 0.05)' : 'white')};
  border: ${({ error }) => (error ? '1px solid #eb5757' : '1px solid #b7bad6')};
  box-sizing: border-box;
  border-radius: 5px;
  color: ${Colors.Blue500};
  width: 100%;
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
`;

const HelpText = styled.span`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333;
  margin: 14px 0;
`;

const GhostButton = styled.button.attrs({ type: 'button' })`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border: 0 none;
  background: transparent;
  color: ${Colors.Blue500};
  position: absolute;
  right: 20px;
  top: 12px;
`;

function renderHelpText(helpText: PasswordInputProps['helpText']): ReactNode | string | null {
  if (isEmptyOrNil(helpText)) {
    return null;
  }

  if (typeof helpText === 'string') {
    return <HelpText>{helpText}</HelpText>;
  }

  if (isValidElement(helpText)) {
    return helpText;
  }

  throw new Error('FileInput: Invalid render text prop');
}

export const PasswordInput: FC<PasswordInputProps> = forwardRef((props, ref) => {
  const uid = useUID();
  const [type, setType] = useState('password');
  const { label, helpText, inputStyle, ...rest } = props;

  return (
    <Container>
      {label && <Label htmlFor={uid}>{label}</Label>}

      <div className="relative">
        <TextInput ref={ref} style={inputStyle} type={type} id={uid} {...rest} />

        <GhostButton onClick={() => setType(type === 'password' ? 'text' : 'password')}>
          {type === 'password' ? 'Show' : 'Hide'}
        </GhostButton>
      </div>

      {renderHelpText(helpText)}
    </Container>
  );
});

PasswordInput.defaultProps = {
  label: '',
  helpText: '',
  fullWidth: false,
  inputStyle: {},
};
