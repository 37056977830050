import { AxiosError } from 'axios';
import Link from 'gatsby-link';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import React, { FC } from 'react';
import { CheckCircle, XOctagon } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { PasswordInput } from '../components/PasswordInput';
import { PasswordRules } from '../components/PasswordRules';
import { H4, H5 } from '../components/Typography';
import { createUser as createUserAction } from '../lib/actions/me';
import { Paths } from '../paths';
import { getBodyMessage } from '../utils/function-utils';
import { HelpText } from './commonStyles';

const Container = styled.div`
  max-width: 820px;
  padding: 60px 50px;
`;

interface Props {
  onComplete?: () => void;
  okText?: string;
  subtitle?: string;
}

export const SignupForm: FC<Props> = ({ onComplete, okText = 'Submit', subtitle }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch,
  } = useForm();
  const password = watch('password', '');
  const phoneNumber = watch('phoneNumber', '');
  const dispatch = useDispatch();
  async function signup(email: string, password: string, phoneNumber: string) {
    try {
      await dispatch(createUserAction(email, password, phoneNumber));
      toast.success('Your user was created successfully. Please check your email.', {
        icon: <CheckCircle className="w-8" size={32} color="#3CB043" />,
      });
      onComplete && onComplete();
    } catch (err) {
      toast.error(getBodyMessage(err as AxiosError), {
        icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
      });
    }
  }

  const onSubmit = async (values: { email: string; phoneNumber: string; password: string }) => {
    await signup(values.email, values.password, values.phoneNumber);
  };

  const isValidPhoneNumber = (num: string) => {
    try {
      return parsePhoneNumber(num).isValid();
    } catch (e) {
      return false;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full">
        <H4 className="mb-6 text-center">Create a free account</H4>

        {subtitle && <H5 className="mb-6 text-center">{subtitle}</H5>}

        <Input
          label="Email Address"
          className="mb-4"
          error={!!errors.email}
          helpText={
            errors.email && (
              <HelpText>{errors.email?.type === 'required' ? 'Email is required' : 'Email is invalid'}</HelpText>
            )
          }
          inputStyle={{ color: '#333' }}
          {...register('email', {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })}
        />

        <Input
          label="Phone Number"
          className="mb-4"
          value={phoneNumber}
          error={!!errors.phoneNumber}
          helpText={errors.phoneNumber && <HelpText>{errors.phoneNumber.message}</HelpText>}
          inputStyle={{ color: '#333', background: '#ddd' }}
          {...register('phoneNumber', {
            required: false,
            validate: (value: string) =>
              value.length === 0 ||
              (!value.startsWith('+')
                ? 'Please input your phone number in the international format'
                : isValidPhoneNumber(value) || 'Please input a valid phone number'),
            setValueAs: (value: string) => new AsYouType().input(value),
          })}
        />

        <PasswordInput
          label="Password"
          className="mb-4"
          error={!!errors.password}
          inputStyle={{ color: '#333' }}
          {...register('password', {
            required: true,
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&\[\]\{\}_\-\(\)])[A-Za-z\d@#$!%*?&\[\]\{\}_\-\(\)]{6,}$/gm,
          })}
        />

        <PasswordRules password={password} />

        <div className="flex justify-end mt-4">
          <div className="flex flex-col mr-auto">
            <Link to={Paths.SignIn}>Already have an account? Sign In</Link>
            <Link to={Paths.RequestVerification}>Resend verification email</Link>
          </div>

          <Button type="submit" disabled={isSubmitting}>
            {okText}
          </Button>
        </div>
      </Container>
    </form>
  );
};
